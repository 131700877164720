.pf-c-expandable-section__content {
    margin-top: 0 !important;
}

.pf-c-expandable-section__toggle {
    font-weight: bold !important;
    margin-left: var(--pf-global--spacer--md) !important;
}

.AnchorMenu {
    position: absolute !important;
    right: 0;
}