.DetailsDrawer {
    padding: 0;
}

.DetailsDrawerButtonContainer {
    padding: var(--pf-global--spacer--lg) !important;
}

.DetailsDrawer .BottomSpacer {
    margin-bottom: var(--pf-global--spacer--lg);
}

.DetailsDrawer .pf-c-data-list {
    margin: 0 var(--pf-global--spacer--lg);
}

.DetailsDrawer .pf-c-data-list {
    border: none;
}

.DetailsDrawer .pf-c-data-list__item {
    background-color: var(--pf-global--Color--light-100);
    border-bottom-color: var(--pf-global--Color--light-300);
}

.ProgressAnimation {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}