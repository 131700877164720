.Toolbar {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.SearchInput {
    margin: 0 !important;
}

.StatusIcon {
    font-size: var(--pf-global--icon--FontSize--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
}

.LeftSpace {
    margin-left: var(--pf-global--spacer--md) !important;
}

.UpperRight {
    position: absolute;
    top: var(--pf-global--spacer--md) !important;
    right: 0 !important;
}

.Inactive {
    color: var(--pf-global--Color--dark-200) !important;
}

.SensorDiscPage .LoadSwitch {
    --pf-c-toggle-group__button--m-selected--BackgroundColor:#c9190b !important;
}

.SensorDiscPage .LoadSwitch .pf-m-selected {
    --pf-c-toggle-group__button--Color:#fff !important;
}

.SensorDiscPage .TemperatureSwitch {
    --pf-c-toggle-group__button--m-selected--BackgroundColor:#06c !important;
}

.SensorDiscPage .TemperatureSwitch .pf-m-selected {
    --pf-c-toggle-group__button--Color:#fff !important;
}

.SensorDiscPage .Distinguished {
    margin: -40px;
    padding: 40px;
    background-color: whitesmoke;
}