.StickyCharts {
    box-shadow: var(--pf-global--BoxShadow--sm-top) !important;
    background-color: whitesmoke !important;
}

.StickyCharts .pf-c-drawer__splitter {
    background-color: whitesmoke !important;
}

.StickyCharts .pf-c-drawer__splitter:active {
    background-color: var(--pf-global--palette--blue-400) !important;
    cursor: none !important;
}

.StickyCharts .pf-c-drawer__splitter-handle {
    background-color: var(--pf-global--palette--blue-100) !important;
}

.StickyCharts .pf-c-drawer__splitter::after {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
}

.StickyCharts .pf-c-drawer__body {
    padding: 0 !important;
}

.StickyCharts .pf-c-panel__footer {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: var(--pf-global--palette--black-200) !important;
    padding: 8px 16px !important;
}

.StickyCharts .pf-c-tabs__item.pf-m-action {
    --pf-c-tabs__link--BackgroundColor: #dddddd !important;
}

.StickyCharts .pf-c-tabs.pf-m-box .pf-c-tabs__item.pf-m-current {
    --pf-c-tabs__link--BackgroundColor: whitesmoke !important;
}

.ChartHeader {
    padding: 0 var(--pf-global--spacer--md) !important;
    position: relative !important;
}

.StickyChart {
    margin-bottom: var(--pf-global--spacer--md) !important;
}

.StickyCharts .ForceSwitch {
    --pf-c-toggle-group__button--m-selected--BackgroundColor:#c9190b !important;
}

.StickyCharts .ForceSwitch .pf-m-selected {
    --pf-c-toggle-group__button--Color:#fff !important;
}

.StickyCharts .TemperatureSwitch {
    --pf-c-toggle-group__button--m-selected--BackgroundColor:#06c !important;
}

.StickyCharts .TemperatureSwitch .pf-m-selected {
    --pf-c-toggle-group__button--Color:#fff !important;
}

#anchor-select-toggle {
    background-color: white !important;
}

.ToolbarIcon {
    font-size: var(--pf-global--icon--FontSize--md) !important;
    position: relative !important;
    top: 3px !important;
}

.StickyChartsTabContent:focus {
    outline: none !important;
}

.ProgressAnimation {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

.PickDate {
    min-width: 240px !important;
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
