.pf-c-brand {
    width: 200px;
    pointer-events: none;
}

.pf-c-button svg {
    margin-right: 0.5em;
}

div#root > div {
    height: 100%;
}

.LoginPage {
    background-color: var(--pf-global--palette--black-150) !important;
}