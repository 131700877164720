td > svg {
    margin-right: var(--pf-global--spacer--sm);
}

.Unknown {
    color: var(--pf-global--disabled-color--200);
}
.Info {
    color: var(--pf-global--info-color--100);
}
.Success {
    color: var(--pf-global--success-color--100);
}
.Warning {
    color: var(--pf-global--warning-color--100);
}
.Danger {
    color: var(--pf-global--danger-color--100);
}