.FischerLogo {
    width: 180px !important;
    position: relative !important;
    top: 2px !important;
}

.PageTitle {
    color: var(--pf-global--Color--100) !important;
    margin-left: var(--pf-global--spacer--xs);
    opacity: 0.8;
}

.Semi {
    opacity: 0.75;
}

.TopSpacer {
    margin-top: var(--pf-global--spacer--lg);
}

.TopExtraSpacer {
    margin-top: var(--pf-global--spacer--xl);
}

.LeftSpacer {
    margin-left: var(--pf-global--spacer--lg);
}

.RightSpacer {
    margin-right: var(--pf-global--spacer--xs);
}

.TopSpacer {
    margin-top: var(--pf-global--spacer--lg);
}

.SearchInput {
    margin-left: var(--pf-global--spacer--lg);
    width: 300px !important;
}

.Value {
    font-weight: bold;
    color: var(--pf-global--active-color--100);
}

.RefreshButton {
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.RefreshButton:hover {
    background: var(--pf-global--BackgroundColor--200) !important;
}

.RefreshButton:active {
    background: none !important;
}

tr.pf-m-selected {
    background-color: var(--pf-global--BackgroundColor--200) !important;
}

.Notification {
    background-color: var(--pf-global--BackgroundColor--200) !important;
    margin-bottom: var(--pf-global--spacer--lg);
}

.Notification i {
    color: var(--pf-global--palette--red-200);
    font-weight: bold;
}

.ScrollableDropdownContainer {
    overflow-y: auto !important;
    max-height: 400px !important;
}

.PageContent {
    background-color: var(--pf-global--BackgroundColor--200);
    padding: var(--pf-global--spacer--lg);
}

.PageNavigation {
    background: var(--pf-global--Color--light-100) !important;
}

.PageBackground {
    background-color: var(--pf-global--BackgroundColor--200);
}

.NoPadding {
    padding: 0;
}

.NoBottomPadding {
    padding-bottom: 0;
}


