.Title {
    margin-bottom: var(--pf-global--spacer--lg);
}

#total.Card {
    background-color: var(--pf-global--palette--blue-200) !important;
}

#provisioned.Card {
    background-color: var(--pf-global--palette--green-200) !important;
}

#outdated.Card {
    background-color: var(--pf-global--palette--gold-200) !important;
}

#warnings.Card {
    background-color: var(--pf-global--palette--orange-300) !important;
}

#errors.Card {
    color: var(--pf-global--Color--light-100) !important;
    background-color: var(--pf-global--palette--red-100) !important;
}

.MapCard {
    border: none !important;
}

.MapBody {
    padding: 0 !important;
}